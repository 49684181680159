const awsmobile = {

  aws_project_region: "ap-south-1",
  aws_cognito_identity_pool_id:
    "ap-south-1:2335c315-f48a-4e4d-9540-da1d94f169ee",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_xp2T0u1cv",
  aws_user_pools_web_client_id: "lq1tj28mfg5oe5j7prm5d6197",
  aws_access_key_id: "AKIAYEKP5EN5SQS4IJU3",
  aws_secret_access_key: "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",
  s3_bucket: "vcip-liveness",
  
};
export default awsmobile;
